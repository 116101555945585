'use client'
import { gql } from '@apollo/client'
import compact from 'lodash/compact'
import first from 'lodash/first'
import groupBy from 'lodash/groupBy'
import MiniSearch from 'minisearch'
import { morphism, StrictSchema } from 'morphism'
import * as React from 'react'
import {
  enum_stripe_product_type_enum,
  LabContentQuery,
  LabProductsQuery,
  StripeProductPriceFieldsFragment,
  useLabProductsSuspenseQuery,
  useLabContentSuspenseQuery,
  useUserSexSuspenseQuery,
} from '../../../../../generated/graphql'
import { hasKey } from '../../../../../typescript/guards/hasKey'
import { typedFalsyFilter } from '../../../../../typescript/guards/typedFalsyFilter'
import { LEGACY_Product } from '../../../hooks/useShoppingCart/LEGACY_Product'
import { getShopPagesPriceDisplay, GetShopPagesPriceDisplayReturn } from '../../../utils/getShopPagesPriceDisplay'
import { ValuesType } from 'utility-types'

gql`
  query UserSex {
    me_v2 {
      sex
    }
  }
`

gql`
  query LabProducts @cached(ttl: 60) {
    tests: product_catalog(where: { product_type: { _in: [LAB_TEST, LAB_PANEL] } }) {
      id
      slug
      ...StripeProductPriceFields
      name
      product_type
      metadata
      lab_items(
        where: { lab_test: { products: { product: { product_type: { _eq: LAB_TEST } } } } }
        order_by: { lab_test: { display_name: asc } }
      ) {
        lab_test {
          products(where: { product: { product_type: { _eq: LAB_TEST } } }) {
            product {
              id
              slug
            }
          }
          id
          display_name
        }
      }
      markers(where: { lab_test_marker: { include_in_overview: { _eq: true } } }) {
        lab_test_marker {
          labels {
            label {
              name
            }
          }
          metric_id
          display_name: display_name_gen
          long_common_name
          synonyms
          loinc_number
        }
      }
      labels {
        label {
          name
        }
      }
    }
    packages: product_catalog(
      where: { product_type: { _eq: PACKAGE }, metadata: { _contains: { featured_package: "true" } } }
    ) {
      slug
      id
      ...StripeProductPriceFields
      name
      metadata
      product_type
      marker_counts {
        label {
          name
        }
        quantity
      }
      lab_items(
        where: { lab_test: { products: { product: { product_type: { _eq: LAB_TEST } } } } }
        order_by: { lab_test: { display_name: asc } }
      ) {
        lab_test {
          products(where: { product: { product_type: { _eq: LAB_TEST } } }) {
            product {
              id
              slug
            }
          }
          id
          display_name
        }
      }
    }

    phenoAge: get_metric_by_namespace_key(args: { namespace: "PHENO_AGE", key: "PHENO_AGE" }) {
      id
    }
  }
`

gql`
  query LabContent($productSlugs: [String!]!, $productLimit: Int, $markerMetricIds: [String!]!, $markerLimit: Int)
  @cached(ttl: 600) {
    cms {
      products: stripeProducts(filters: { slug: { in: $productSlugs } }, pagination: { limit: $productLimit }) {
        data {
          attributes {
            slug
            summary
            description
          }
        }
      }
      markers: labTestMarkers(filters: { entity_id: { in: $markerMetricIds } }, pagination: { limit: $markerLimit }) {
        data {
          attributes {
            metric_id: entity_id
            summary
            full_description
          }
        }
      }
    }
  }
`

type LabProductData = LabProductsQuery['tests'][number]
type Marker = Omit<
  NonNullable<LabProductData['markers'][number]['lab_test_marker']> & ValuesType<ProcessedLabContent['markers']>,
  '__typename'
>

const markerToTextBlob = (marker: Marker): string => {
  const { display_name, long_common_name, synonyms, loinc_number } = marker

  let textParts = compact([display_name, long_common_name, loinc_number, ...(synonyms ?? [])])
  return textParts.join('\n')
}

export type ProcessedPrice = { numericCents: number | null; display: GetShopPagesPriceDisplayReturn }

export const getProcessedPrice = (price: number | undefined | null) => {
  const numericCents = price ?? null
  return { numericCents, display: getShopPagesPriceDisplay(numericCents) }
}

export type Prices = {
  freeMember: ProcessedPrice
  current: ProcessedPrice
  paidMember: ProcessedPrice
}
export interface LabTest {
  id: string
  sortOrder: number
  slug: string
  name: string
  description?: string
  summary?: string
  prices: Prices
  cartProduct: LEGACY_Product & {
    productType: enum_stripe_product_type_enum
  }
  categories: string[]
  markers: Marker[]
}

const getProductDescription = (product: LabProductData, labContent: ProcessedLabContent) => {
  const productDescription = labContent.products[product.slug ?? '']?.description

  if (productDescription && product.product_type !== enum_stripe_product_type_enum.LAB_TEST) {
    return productDescription
  }

  const labTestDescription =
    labContent.products[first(product.lab_items)?.lab_test?.products?.[0]?.product?.slug ?? '']?.description
  return labTestDescription ?? productDescription
}

export const allCategoriesName = 'All Categories'

const makeLabTestSchema: (labContent: ProcessedLabContent) => StrictSchema<LabTest, LabProductData> = (
  labContent: ProcessedLabContent
) => ({
  id: 'id',
  sortOrder: ({ metadata }) => Number(metadata.sort_order ?? 0),
  name: 'name',
  prices: (product) => getPrices(product),
  slug: (product) => product.slug ?? '',
  description: (product) => getProductDescription(product, labContent),
  summary: (product) => labContent.products[product.slug ?? '']?.summary,
  cartProduct: (product) => {
    const labTestDescriptionMatch =
      labContent.products[first(product.lab_items ?? [])?.lab_test?.products?.[0]?.product?.slug ?? '']?.description

    const productDescriptionMatch = labContent.products[product.slug ?? '']?.description
    return {
      id: product.id,
      name: product.name,
      price: Infinity ?? Infinity ?? Infinity,
      prices: getPrices(product),
      description: labTestDescriptionMatch ?? productDescriptionMatch ?? undefined,
      currency: 'USD',
      productType: product.product_type,
    }
  },
  categories: (product) => product.labels.map(({ label: { name } }) => name).concat(allCategoriesName),
  // !!revisit this and remove product from the query and grab content directly when sam adds
  markers: (product) => {
    const original = compact(product.markers.map((marker) => marker?.lab_test_marker))
    return original.map((x) => ({ ...x, ...labContent.markers[x.metric_id] }))
  },
})

const transform = (data: LabProductData[] = [], labContent: ProcessedLabContent) => {
  const productItems = morphism(makeLabTestSchema(labContent), data)

  const categoryItems = Object.entries(
    groupBy(
      productItems.map((product) => product.categories.map((category) => ({ category, product }))).flat(),
      'category'
    )
  )
    .map(([category, items]) => ({
      name: category,
      tests: items
        .map((item) => item.product)
        .sort((a, b) => (a.sortOrder != b.sortOrder ? a.sortOrder - b.sortOrder : a.name.localeCompare(b.name))),
    }))
    .sort((a, b) => (b.tests.length != a.tests.length ? b.tests.length - a.tests.length : a.name.localeCompare(b.name)))

  const miniSearch = new MiniSearch<LabTest>({
    fields: ['id', 'name', 'categories', 'markers'], // fields to index for full-text search
    extractField: (document, fieldName) => {
      if (!hasKey(document, fieldName)) {
        return ''
      }

      if (fieldName === 'markers') {
        return document.markers.map(markerToTextBlob).join('\n')
      }

      return document[fieldName]?.toString() ?? ''
    },
    searchOptions: {
      boost: { title: 10, categories: 2 },
      fuzzy: 0.2,
      prefix: true,
    },
  })
  miniSearch.addAll(productItems)

  return { productItems, categoryItems, miniSearch }
}

type FeaturedPackagesData = LabProductsQuery['packages'][number]
type LabItemsData = FeaturedPackagesData['lab_items'][number]

export interface Battery {
  id: string
  name: string
  description?: string | null
  summary: string
}

export interface Package {
  sortOrder: number
  name: string
  slug: string
  summary?: string
  description?: string
  prices: Prices
  battery: Battery[]
  markerCounts: Record<string, number>
  cartProduct: LEGACY_Product & {
    productType: enum_stripe_product_type_enum
  }
}

const makeBatterySchema: (labContent: ProcessedLabContent) => StrictSchema<Battery, LabItemsData> = (
  labContent: ProcessedLabContent
) => ({
  id: (x) => x.lab_test?.products?.[0]?.product?.id ?? '',
  name: 'lab_test.display_name',
  description: (sp) => labContent.products[sp.lab_test?.products?.[0]?.product.slug ?? '']?.description,
  // !!revisit this and remove product from the query and grab content directly when sam adds
  summary: (sp) => labContent.products[sp.lab_test?.products?.[0]?.product.slug ?? '']?.summary ?? '',
})

export function getPrices(stripeProductPriceFields: StripeProductPriceFieldsFragment) {
  const { price_free_member, price_paid_member, price_current } = stripeProductPriceFields
  return {
    freeMember: getProcessedPrice(price_free_member?.price_usd),
    current: getProcessedPrice(price_current?.price_usd),
    paidMember: getProcessedPrice(price_paid_member?.price_usd),
  }
}
const makePackageSchema: (labContent: ProcessedLabContent) => StrictSchema<Package, FeaturedPackagesData> = (
  labContent: ProcessedLabContent
) => ({
  sortOrder: ({ metadata }) => Number(metadata.sort_order ?? 0),
  name: 'name',
  slug: (product) => product.slug ?? '',
  description: (product) => labContent.products[product.slug ?? '']?.description,
  summary: (product) => labContent.products[product.slug ?? '']?.summary,
  prices: (product) => getPrices(product),
  battery: ({ lab_items }) => morphism(makeBatterySchema(labContent), lab_items),
  markerCounts: (product) =>
    Object.fromEntries(product.marker_counts.map(({ label, quantity }) => [label!.name, quantity!])),
  cartProduct: (product) => ({
    id: product.id,
    name: product.name,
    price: Infinity ?? Infinity ?? Infinity,
    prices: getPrices(product),
    lineItems: product.lab_items.map((x) => x.lab_test?.display_name).filter(typedFalsyFilter),
    description: labContent.products[product.slug ?? '']?.description,
    currency: 'USD',
    productType: product.product_type,
  }),
})

const Context = React.createContext<undefined | ReturnType<typeof useProcessLabProducts>>(undefined)

type ProductContentData = NonNullable<
  NonNullable<NonNullable<LabContentQuery['cms']>['products']>['data'][number]['attributes']
>
type MarkerContentData = NonNullable<
  NonNullable<NonNullable<LabContentQuery['cms']>['markers']>['data'][number]['attributes']
>

type ProcessedLabContent = ReturnType<typeof useLabContent>['labContent']

const useLabContent = (data: LabProductsQuery | undefined) => {
  const slugsAndMetricIds = React.useMemo(() => {
    const testSlugs = (data?.tests ?? []).map((x) => x.slug).filter(typedFalsyFilter)

    const testItemProductSlugs = (data?.tests ?? [])
      .flatMap((x) => x.lab_items.flatMap((x2) => x2.lab_test?.products.flatMap((x3) => x3.product.slug)))
      .filter(typedFalsyFilter)

    const markerMetricIds = (data?.tests ?? [])
      .map((x) => x.markers.map((x2) => x2.lab_test_marker?.metric_id))
      .flat()
      .filter(typedFalsyFilter)

    const packageSlugs = (data?.packages ?? []).map((x) => x.slug).filter(typedFalsyFilter)
    const packageTestSlugs = (data?.packages ?? [])
      .flatMap((x) => x.lab_items.flatMap((x2) => x2.lab_test?.products.map((x3) => x3.product.slug)))
      .filter(typedFalsyFilter)

    const productSlugs = [...new Set([...testSlugs, ...testItemProductSlugs, ...packageSlugs, ...packageTestSlugs])]
    return {
      productSlugs,
      markerMetricIds,
      productLimit: productSlugs.length,
      markerLimit: markerMetricIds.length,
    }
  }, [data])

  const labContentResult = useLabContentSuspenseQuery({
    variables: slugsAndMetricIds,
    skip: !slugsAndMetricIds.productLimit && !slugsAndMetricIds.markerLimit,
  })

  const labContent = React.useMemo(() => {
    const products = (labContentResult.data?.cms?.products?.data.map((x) => x.attributes) ?? []).reduce(
      (acc, x) => (x ? { ...acc, [x.slug]: x } : acc),
      {} as Record<string, ProductContentData>
    )
    const markers = (labContentResult.data?.cms?.markers?.data.map((x) => x.attributes) ?? []).reduce(
      (acc, x) => (x ? { ...acc, [x.metric_id]: x } : acc),
      {} as Record<string, MarkerContentData>
    )
    return { products, markers }
  }, [labContentResult.data])
  return { labContent, labContentLoading: !labContentResult.data && !labContentResult.error }
}

const useProcessLabProducts = () => {
  const { data: userSexData } = useUserSexSuspenseQuery()

  const userSex = userSexData?.me_v2?.sex

  const { data, error } = useLabProductsSuspenseQuery()

  const { labContent, labContentLoading } = useLabContent(data)

  const packageData = React.useMemo(() => {
    const packagesWithoutSexFilter = data?.packages ?? []

    const packages = packagesWithoutSexFilter.filter(
      (x) => !x.metadata.sex_filter || !userSex || x.metadata.sex_filter === userSex
    )

    const packageSchema = makePackageSchema(labContent)

    return {
      packages: morphism(packageSchema, packages).sort((a, b) => a.sortOrder - b.sortOrder),
      packagesWithoutSexFilter: morphism(packageSchema, packagesWithoutSexFilter).sort(
        (a, b) => a.sortOrder - b.sortOrder
      ),
      phenoAgeMetricId: data?.phenoAge?.id ?? '',
    }
  }, [data, userSex, labContent])

  const labProductsData = React.useMemo(() => transform(data?.tests, labContent), [data?.tests, labContent])

  const value = React.useMemo(
    () => ({ packageData, userSex, labProductsData, error, labContentLoading }),
    [packageData, labProductsData, error, userSex, labContentLoading]
  )
  return value
}

// Believe we use usePackages in other places in the app that don't need the context layer, so separating into
// usePackages and usePackagesContext so we can use either in a context location or just as a standalone hook that
// fetches the data
export function LabProductsProvider({ children }: React.PropsWithChildren<{}>) {
  const value = useProcessLabProducts()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useLabProducts() {
  const context = React.useContext(Context)
  if (!context) throw new Error("'usePackagesContext' must be used within a LabProductsProvider")
  return context
}
